<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new tax</div>
      <div v-else class="p-dialog-title">Tax editing</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="tax_name" v-model.trim="taxData.name" autocomplete="off"/>
          <label for="tax_name">Name</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="tax_value" :inputClass="{'p-invalid' : submitted && !taxData.value}" v-model="taxData.value" mode="decimal" suffix="%" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="60" showButtons autocomplete="off"/>
          <label for="tax_value">Value<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !taxData.value && taxData.value !== 0">{{ $t('Required field') }}</small>
      </div>
    </div>
    <div class="p-d-flex p-ai-center">
      <div class="p-d-flex p-ai-center">
        <InputSwitch id="is_default_tax_modal" v-model="isDefault"/>
      </div>
      <label for="is_default_tax_modal" class="switch-label pointer">Make default?</label>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ httpMixins, formatMixins ],
  emits: ['close', 'update-items'],
  name: 'TaxModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      taxData: {},
      submitted: false,
      isDefault: false
    }
  },
  watch: {
    item() {
      this.taxData = { ...this.item }
      if (this.item.value) {
        this.taxData.value = +this.item.value
      }

      this.isDefault = !!this.item.is_default
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.taxData.value && this.taxData.value !== 0) {
        return false
      }

      const tax = {
        id: this.taxData.id ?? null,
        name: this.taxData.name ? this.taxData.name : null,
        value: this.taxData.value ? this.taxData.value : null,
        is_default: this.isDefault,
      }

      if (this.taxData.id) {
        try {
          const { status } = await httpClient.post(`tax/update-tax`, tax)
          if (status === 200) {
            this.$emit('update-items', this.taxData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`tax/create-tax`, tax)
          if (status === 201 && data) {
            this.taxData.id = data
            tax.id = data
            this.$emit('update-items', this.taxData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>